.main-layout {
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #1890ff;
    }
  }

  .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: bold;
  }

  .main-sider {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
  }

  .main-header {
    display: flex !important;
    align-items: center;

    .header-notifications {
      flex: 1;
      overflow: hidden;

      .marquee {
        overflow: hidden;

        .--container {
          padding-left: 100%;
          display: inline-flex;
          align-items: center;
          white-space: nowrap;

          .--item {
            display: inline-block;
            white-space: nowrap;
          }

          .--item + .--item {
            margin-left: 25px;
          }
        }
      }
    }
  }

  .main-content {
    background: #fff;
    padding: 24px;
    margin-bottom: 15px;
  }

  .main-breadcrumb {
    margin: 16px 0;
  }

  .main-layout-container {
    min-height: 100vh;
    margin-left: 200px;
    transition: margin-left ease-out 0.3s;

    &.sider-collapsed {
      margin-left: 80px;
    }
  }

  .main-footer {
    padding-left: 18px;
    padding-right: 18px;
  }
}
