.notifications {
  .ant-badge-count {
    transform: translate(50%, -50%) scale(1);
    animation: notification-pulse 2s infinite;
    background: #ff4d4f;
  }
}

.notification-list {
  max-height: 50vh;
  padding: 10px 0;
  overflow: auto;

  .notification-item {
    padding: 6px 10px;
    border-bottom: 1px solid #f0f0f0;

    &:hover {
      background: #f0f0f0;
    }
  }
}

.notification-item {
  padding: 10px 0;
  color: rgba(0, 0, 0, 0.45);
  display: block;

  &:last-child {
    border-bottom: none;
  }

  a {
    color: rgba(0, 0, 0, 0.45);
  }

  &.unread {
    color: #000;

    a {
      color: #000;
    }
  }

  &.has-more {
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
  }

  > * {
    display: inline-block;
  }

  .--avatar {
    margin-right: 10px;
  }

  .--time {
    margin-left: 10px;
    color: rgba(0, 0, 0, 0.5);
  }
}

.notifications-dropdown {
  width: 300px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .15);

  .show-more {
    display: block;
    padding: 10px;
    border-top: 1px solid #e8e8e8;
    text-align: center;
  }

  .ant-tabs-nav {
    margin-bottom: 0 !important;
  }

  .ant-tabs-nav-wrap {
    padding: 0 15px;
  }

  .ant-tabs-nav-list {
    display: flex;
    width: 100%;

    .ant-tabs-tab {
      flex: 1;
    }
  }
}

@keyframes notification-pulse {
  0% {
    transform: translate(50%, -50%) scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 77, 79, 0.5);
  }

  70% {
    transform: translate(50%, -50%) scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: translate(50%, -50%) scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
