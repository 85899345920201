.birthday-modal {
  .birthday-modal-wrap {
    pointer-events: none;
  }

  .birthday-modal-content {
    pointer-events: all;
    position: relative;
  }

  iframe {
    border: none;
    outline: none;
    overflow: hidden;
    z-index: 1;
  }

  .close-button {
    position: absolute;
    z-index: 2;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
}
