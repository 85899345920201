.new-version {
  h1, h2, h3 {
    font-size: 1rem;
    font-weight: 500;
  }
  .changes {
    max-height: 500px;
    overflow: auto;
  }
}
