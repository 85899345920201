@import '~antd/dist/antd.css';
@import '~@ant-design/compatible/assets/index.css';
@import './ant-compatible';
@import "./task-status";

html, body {
  font-family: 'Roboto', sans-serif;
}

pre {
  font-family: inherit;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a0a0a0;
}

a {
  &.color-black {
    color: rgba(0, 0, 0, 0.8);

    &:hover {
      color: rgba(0, 0, 0, 1);
    }
  }
}

pre {
  white-space: pre-wrap;
}

img {
  max-width: 100%;
}

.mt-10 {
  margin-top: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.w-150 {
  width: 150px !important;
}

.w-200 {
  width: 200px !important;
}

.ant-legacy-form-item {
  margin-bottom: 10px;
}

.ant-modal-body {
  max-height: calc(100vh - 320px);
  overflow: auto;
}

.text-green {
  color: #006c00;
}

.text-gray {
  color: #9c9c9c;
}

.public-layout {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../images/login-img.jpg") no-repeat fixed center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  padding-bottom: 100px;

  footer {
    position: fixed;
    z-index: 1000;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    color: #e0e0e0;
  }
}

.login-form {
  max-width: 350px;
  background: #fff;
  padding: 20px 40px;
  border-radius: 15px;
  margin: 10px auto;

  .login-form-button {
    width: 100%;
  }
}

.download-app {
  max-width: 450px;
  background: #fff;
  padding: 20px 40px;
  border-radius: 15px;
  margin: 10px auto;

  .download-button {
    margin-bottom: 10px;
  }

  .stable-version {
    text-align: center;
  }

  .all-versions {
    background: transparent;
    border: none !important;

    > .ant-collapse-item {
      border: none !important;
    }

    .ant-collapse-header {
      color: #1890ff;
    }

    .ant-collapse-content {
      border: none !important;
    }

    .version-item {
      display: block;
      margin-bottom: 10px;

      i {
        margin-right: 5px;
      }
    }

    .ant-collapse-content-box {
      padding: 0 16px;
    }
  }
}

.common-form {
  button + button {
    margin-left: 10px;
  }
  .ant-dropdown-button {
    button + button {
      margin-left: 0;
    }
  }
  .form-actions {
    display: flex;
    margin-top: 15px;
  }
}

.common-table + .action-buttons {
  margin-top: 15px;
}

.main-header {
  .header-components {
    padding: 0 24px;
    float: right;
    white-space: nowrap;

    a {
      color: rgba(0, 0, 0, 0.65);
    }

    .search-box {
      text-align: right;
      display: inline-block;
    }

    .header-icon {
      display: inline-block;
      padding: 0 15px;
      height: 100%;
      cursor: pointer;
      &.network-status {
        cursor: default;
        i {
          margin-right: 5px;
        }
      }
    }

    .notifications {
      &:hover, &.ant-dropdown-open {
        background: rgba(0, 0, 0, .025);
      }

      .ant-badge-count {
        top: -3px;
        right: -3px;
      }
    }
  }
}

.common-table {
  .actions {
    a {
      color: #333;
    }

    a + a {
      margin-left: 10px;
    }
  }
}

.text-center {
  text-align: center;
}

.d-flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.ant-table-scroll {
  .ant-table-body {
    overflow-x: auto !important;
  }
}

.tox .tox-statusbar {
  display: none !important;
}

.html-view {
  table[border]:not([border="0"]):not([style*=border-color]) td, table[border]:not([border="0"]):not([style*=border-color]) th {
    border-color: #e8e8e8;
  }

  table[border]:not([border="0"]):not([style*=border-style]) td, table[border]:not([border="0"]):not([style*=border-style]) th {
    border-style: solid;
  }

  table[border]:not([border="0"]):not([style*=border-width]) td, table[border]:not([border="0"]):not([style*=border-width]) th {
    border-width: 1px;
  }

  table:not([cellpadding]) td, table:not([cellpadding]) th {
    padding: 0.8rem 1rem;
  }

  th {
    text-align: center;
    background-color: #fafafa;
  }

  p {
    margin-bottom: 0;
  }

  img {
    cursor: pointer;
  }

  .auto-complete-tag {
    background: none;
    padding: 0;
  }
}

.inline-editor {
  .mce-content-body {
    min-height: 80px;
    max-height: 200px;
    overflow-y: auto;
    line-height: 1.5;
    outline: none;
    padding: 0.5rem;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    table[border]:not([border="0"]):not([style*=border-color]) td, table[border]:not([border="0"]):not([style*=border-color]) th {
      border-color: #ccc;
    }

    table[border]:not([border="0"]):not([style*=border-style]) td, table[border]:not([border="0"]):not([style*=border-style]) th {
      border-style: solid;
    }

    table[border]:not([border="0"]):not([style*=border-width]) td, table[border]:not([border="0"]):not([style*=border-width]) th {
      border-width: 1px;
    }

    table:not([cellpadding]) td, table:not([cellpadding]) th {
      padding: .4rem;
    }

    th {
      text-align: center;
    }

    p {
      margin-bottom: 0;
    }
  }

  &.--no-border {
    .mce-content-body {
      border: none;
    }
  }

  &.--fit-content {
    .mce-content-body {
      padding: 1px;
      max-height: none;
    }
  }

  &.--medium {
    .mce-content-body {
      min-height: 150px;
      max-height: 550px;
    }
  }

  &.--large {
    .mce-content-body {
      min-height: 250px;
      max-height: 550px;
    }
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.auto-complete-tag {
  background-color: #f0f0f0;
  padding: 0.125em 0.25em;
  border-radius: 0.25em;
}

.filter-section {
  display: flex;
  margin-bottom: 10px;
}

.color-red {
  color: red !important;
}

.color-green {
  color: green !important;
}

.color-orange {
  color: orange !important;
}

.color-inherit {
  color: inherit !important;
}

.hyper-link:hover {
  cursor: pointer;
}
