.page-loading {
  position: fixed;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  z-index: 1000000;
  width: 100px;
  height: 100px;
  margin-top: -60px;
  margin-left: -50px;
  background: rgba(255, 255, 255, 0.75);
  border-radius: 20px;

  .ant-spin {
    position: relative;
    .ant-spin-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 10px;
    }
  }
}
