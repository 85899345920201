$status-todo-color: #999999;
$status-in-progress-color: #0E87CC;
$status-pending-color: #ff8d00;
$status-done-color: #0c9100;
$status-all-color: #001529;

.task-status {
  display: inline-block;
  color: #fff;
  padding: 3px 8px;
  min-width: 110px;
  font-size: 12px;
  text-transform: capitalize;
  border-radius: 4px;
  text-align: center;
}

.change-status {
  .ant-select-selector {
    color: #fff;
    border: none !important;
    box-shadow: none !important;
    border-radius: 4px;
  }
  .ant-select-selection__rendered {
    margin-right: 28px;
  }
  .ant-select-arrow {
    color: #f0f0f0;
  }
}

.status-all {
  &.task-status, .ant-select-selector {
    background-color: $status-all-color !important;
  }
}

.status-todo {
  &.task-status, .ant-select-selector {
    background-color: $status-todo-color !important;
  }
}

.status-in-progress {
  &.task-status, .ant-select-selector {
    background-color: $status-in-progress-color !important;
  }
}

.status-pending {
  &.task-status, .ant-select-selector {
    background-color: $status-pending-color !important;
  }
}

.status-done {
  &.task-status, .ant-select-selector {
    background-color: $status-done-color !important;
  }
}
