.reminder {
  position: fixed;
  z-index: 1000;
  bottom: 15px;
  right: 15px;
  box-shadow: 0 1px 2px -2px #00000029, 0 3px 6px #0000001f, 0 5px 12px 4px #00000017;
  display: none;

  &.open {
    display: block;
  }

  .reminder-header {
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    background: #4C8E5C;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    h3 {
      color: #ffffff;
      margin: 0;
      padding: 0;
      font-weight: 500;
      text-transform: uppercase;
    }

    .icon-close {
      color: #eeeeee;
      margin-right: 5px;
      cursor: pointer;
    }
  }

  .reminder-list {
    background: #ffffff;
    width: 300px;
    max-height: 200px;
    overflow: auto;
    border: 1px solid rgba(0, 0, 0, .06);
    border-top: none;
  }

  .reminder-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, .06);

    .reminder-item-content {
      padding-left: 10px;
    }

    .reminder-item-title {
      font-weight: 500;
    }

    .reminder-item-time {
      font-size: 11px;
      color: #666666;
    }
  }
}

.reminder-toggle {
  position: fixed;
  z-index: 1000;
  bottom: 15px;
  right: 15px;
  cursor: pointer;
  box-shadow: 0 1px 2px -2px #00000029, 0 3px 6px #0000001f, 0 5px 12px 4px #00000017;
  width: 36px;
  height: 36px;
  border-radius: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4C8E5C;
  color: #ffffff;
}
