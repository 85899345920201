.ant-legacy-form-item {
  margin-bottom: 10px;

  &.ant-row {
    flex-direction: column;
  }

  .ant-legacy-form-item-label {
    text-align: left;
  }
}


.ant-dropdown-menu .ant-dropdown-menu-title-content > .anticon {
  margin-right: 5px;
}
